@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.CopyInput {
  position: relative;
  width: 100%;
  max-width: 360px;
  &_input {
    width: 100%;
    max-width: 360px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      font-size: 17px !important;
    }
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    height: 50px;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &_button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    font-size: 16px;
    height: 50px;

    //width: 100px;
    color: #999;

    button {
      height: 100%;
      width: 80px;
      border-bottom-right-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border: none;
      outline: none;
      background-color: #5d8df5;
      color: white;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      color: #000;
    }
  }
  &_button-copied {
    background-color: #5ebf5f;
  }
}

.rtl .CopyInput {
  &_input {
    margin-right: 0;
  }

  &_button {
    left: 0;
    right: auto;
  }
}
