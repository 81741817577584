@import "/src/styles/variables.scss";

.QRState {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  &_dot {
    width: 20pt;
    min-width: 20pt;
    height: 20pt;
    min-height: 20pt;
    border-radius: 50%;
    margin-right: 10px;
    background: #52d052;
  }
  &_dot-red {
    background: #d05252 !important;
  }

  &_text {
    line-height: 1;
    font-size: 16pt;
  }
}

.blink-bg {
  animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground {
  0% {
    background-color: #52d052;
  }
  25% {
    background-color: transparent;
  }
  50% {
    background-color: #52d052;
  }
  75% {
    background-color: transparent;
  }
  100% {
    background-color: #52d052;
  }
}
.QRState-dark {
}

.QR_image {
  width: 300px;
  height: 300px;
  display: inline-flex;
  background-color: white;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  object-fit: contain;
  border-radius: 20px;
  position: relative;
}

.QRState_text {
  font-size: 18pt;
}

.QR_successText {
  font-size: 30pt;
}

.QRState_refreshText {
  font-size: 16pt;
  padding-bottom: 20px;
}

.QR_expireBtn {
  position: absolute;
  background-color: white;
  padding: 30px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.QR_refreshBtn {
  background-color: transparent;
  border: none;
  outline: none;
  svg {
    width: 50px;
    height: 50px;
  }
}

.QR_progress {
  position: absolute;
  background-color: white;
  padding: 30px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
