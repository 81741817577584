.App {
  text-align: center;
}
p {
  margin: 0 !important;
}

body {
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.09)),
    url("https://coolbackgrounds.io/images/backgrounds/index/ranger-4df6c1b6.png")
      no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
